import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useResponsive } from '@/hooks/use-responsive';
import { bgGradient } from '@/theme/css';
import LogoHorizontal from '@/components/logo/logo-horizontal';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { useSettingsContext } from '@/components/settings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useEffect } from 'react';
import { ASSETS_API, ENVIRONMENT } from '@/config-global';
import { EnvIcon } from '@/components/env-icon';
import { LanguagePopover } from '../_common';

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const { t } = useTranslation();
  const settings = useSettingsContext();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    (window as any).intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'q2vbp4ii',
    };
    // eslint-disable-next-line func-names
    (function () {
      const w: any = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const scriptId = 'intercom-script';
        // Remove existing Intercom script if it exists
        const existingScript = d.getElementById(scriptId);
        if (existingScript) {
          existingScript.parentNode?.removeChild(existingScript);
        }
        // eslint-disable-next-line func-names
        const i: any = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        };
        i.q = [];
        // eslint-disable-next-line func-names
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        // eslint-disable-next-line func-names
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.id = scriptId;
          s.src = 'https://widget.intercom.io/widget/q2vbp4ii';
          const x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, []);

  const renderLogo = (
    <Box
      sx={{
        height: '150px',
      }}
    >
      <LogoHorizontal
        sx={{
          zIndex: 9,
          position: 'absolute',
          m: { xs: 2, md: 5 },
        }}
      />
    </Box>
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 520,
      }}
    >
      <Box
        sx={{
          pt: 2,
          pr: { xs: 2, md: 8 },
          display: 'flex',
          // alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <LanguagePopover />
        <IconButton
          sx={{ ml: 1 }}
          color="inherit"
          onClick={() =>
            settings.onUpdate('themeMode', settings.themeMode === 'dark' ? 'light' : 'dark')
          }
        >
          {settings.themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>
      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 520,
          px: { xs: 2, md: 8 },
          pt: { xs: 10, md: 15 },
          pb: { xs: 15, md: 0 },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={5}
      alignItems="center"
      // justifyContent="center"
    >
      <Typography variant="h3" sx={{ maxWidth: 520, textAlign: 'center' }}>
        {title || t('Hi, Welcome back')}
      </Typography>

      <Box
        component="img"
        alt="auth"
        src={image || `${ASSETS_API}/assets/login/login-red.webp`}
        sx={{
          maxWidth: {
            xs: 520,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Stack
        direction="column"
        sx={{
          flex: 1,
          minHeight: '100vh',
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === 'light' ? 0.8 : 0.9
            ),
            imgUrl: `${ASSETS_API}/assets/background/overlay_2.jpg`,
          }),
        }}
      >
        <EnvIcon env={ENVIRONMENT} />

        {renderLogo}

        {mdUp && renderSection}
      </Stack>

      {renderContent}
    </Stack>
  );
}
