export enum OrionModule {
  ACCOUNT = 'ACC',
  PARTNER = 'PRT',
  COMPASS = 'OCC',
}

export const OrionSubmodule = {
  ACCOUNT: {
    COMPANY: 'CMP',
    CONTACT: 'CON',
    SUBSCRIPTION: 'SUB',
    ORDER: 'ORD',
    REQUEST: 'REQ',
  },
  PARTNER: {
    PARTNER: 'PRT',
    PRODUCT: 'PRD',
    SKU: 'SKU',
    PLAN: 'PLN',
    PRICEBOOK: 'PRB',
  },
  COMPASS: {
    ACCOUNTS: 'CCA',
    CONSUMPTIONS: 'CCC',
    INVENTORY: 'CCI',
  },
} as const;

type AccountValues = (typeof OrionSubmodule.ACCOUNT)[keyof typeof OrionSubmodule.ACCOUNT];
type PartnerValues = (typeof OrionSubmodule.PARTNER)[keyof typeof OrionSubmodule.PARTNER];
type CompassValues = (typeof OrionSubmodule.COMPASS)[keyof typeof OrionSubmodule.COMPASS];

export type OrionSubmoduleOptions = AccountValues | PartnerValues | CompassValues;

export enum OrionAccess {
  CREATE = 'create',
  EDIT = 'edit',
  UPDATE = 'update',
  VIEW = 'view',
  DELETE = 'delete',
}
