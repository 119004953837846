// import './instrument';
import App from '@/App';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(<HelmetProvider>
  <BrowserRouter>
    <Suspense>
      <App />
    </Suspense>
  </BrowserRouter>
</HelmetProvider>);
