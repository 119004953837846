import { paths } from '@/routes/paths';
import { useLocales } from '@/locales';
import { useMemo } from 'react';
import { useAuthContext } from '@/auth/hooks';
import { OrionAccess, OrionModule, OrionSubmodule } from '@/types';

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();

  // Accounts
  const accountItems: any[] = [];

  if (user?.checkPermission('ACC', 'CMP', 'view')) {
    accountItems.push({
      title: t('Organizations'),
      path: paths.management.accounts.organizations.root,
    });
  }
  if (user?.checkPermission('ACC', 'CON', 'view')) {
    accountItems.push({ title: t('Contacts'), path: paths.management.accounts.contacts.root });
  }
  if (user?.checkPermission('ACC', 'SUB', 'view')) {
    accountItems.push({
      title: t('Subscriptions'),
      path: paths.management.accounts.subscriptions.root,
    });
  }
  if (user?.checkPermission('ACC', 'ORD', 'view')) {
    accountItems.push({ title: t('Orders'), path: paths.management.accounts.orders.root });
  }
  if (user?.checkPermission('ACC', 'REQ', 'view')) {
    accountItems.push({ title: t('Requests'), path: paths.management.accounts.request.root });
  }

  // Portfolio
  const portfolioItems: any[] = [];
  if (user?.checkPermission('PRT', 'PRT', 'view')) {
    portfolioItems.push({ title: t('Partners'), path: paths.management.portfolio.partners.root });
  }
  if (user?.checkPermission('PRT', 'PRD', 'view')) {
    portfolioItems.push({ title: t('Products'), path: paths.management.portfolio.products.root });
  }
  if (user?.checkPermission('PRT', 'SKU', 'view')) {
    portfolioItems.push({ title: t('SKUs'), path: paths.management.portfolio.skus.root });
  }
  if (user?.checkPermission('PRT', 'PLN', 'view')) {
    portfolioItems.push({ title: t('Plans'), path: paths.management.portfolio.plans.root });
  }
  if (user?.checkPermission('PRT', 'PRB', 'view')) {
    portfolioItems.push({ title: t('Pricebook'), path: paths.management.portfolio.pricebook.root });
  }

  const manageItems: any[] = [];

  if (accountItems.length > 0) {
    manageItems.push({
      title: t('Accounts'),
      path: paths.management.accounts.root,
      icon: 'accountCircle',
      children: accountItems,
    });
  }
  if (portfolioItems.length > 0) {
    manageItems.push({
      title: t('Portfolio'),
      path: paths.management.portfolio.root,
      icon: 'inventory',
      children: portfolioItems,
    });
  }
  if (user?.isAdmin) {
    manageItems.push({
      title: t('Directory'),
      path: paths.management.directory.root,
      icon: 'group',
      children: [
        { title: t('Orioners'), path: paths.management.directory.user.root },
        { title: t('Users'), path: paths.management.directory.usermkt.root },
        { title: t('Teams'), path: paths.management.directory.teams.root },
        { title: t('Roles'), path: paths.management.directory.roles.root },
        { title: t('Transactions'), path: paths.management.directory.banks.root },
      ],
    });
  }

  // OCC Permissions
  const cloudCompassItems = [];

  if (
    user?.checkPermission(OrionModule.COMPASS, OrionSubmodule.COMPASS.ACCOUNTS, OrionAccess.VIEW)
  ) {
    cloudCompassItems.push({
      title: t('Accounts'),
      path: paths.cloudCompass.root,
      icon: 'cloud',
      children: [
        {
          title: t('AWS Payer Accounts'),
          path: paths.cloudCompass.aws.accounts.payerAccounts.root,
        },
        {
          title: t('GCP Billing Accounts'),
          path: paths.cloudCompass.gcp.accounts.masterBillingAccounts.root,
        },
      ],
    });
  }

  if (
    user?.checkPermission(
      OrionModule.COMPASS,
      OrionSubmodule.COMPASS.CONSUMPTIONS,
      OrionAccess.VIEW
    )
  ) {
    cloudCompassItems.push({
      title: t('Reports'),
      path: paths.cloudCompass.reports.root,
      icon: 'dashboard',
      children: [
        {
          title: t('Cost Report'),
          path: paths.cloudCompass.reports.tabs.root,
        },
        {
          title: t('Billing Report'),
          path: paths.cloudCompass.reports.billing.root,
        },
      ],
    });
  }
  if (
    user?.checkPermission(OrionModule.COMPASS, OrionSubmodule.COMPASS.INVENTORY, OrionAccess.VIEW)
  ) {
    cloudCompassItems.push({
      title: t('Resources'),
      path: paths.cloudCompass.resources.root,
      icon: 'inventory',
      children: [
        {
          title: t('Inventory'),
          path: paths.cloudCompass.resources.inventory.root,
        },
        {
          title: t('Virtual Tags'),
          path: paths.cloudCompass.resources.tags.root,
        },
      ],
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const management: any[] = [
    {
      subheader: t('Management'),
      items: [...manageItems],
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cloudCompass: any[] = [];

  if (cloudCompassItems.length > 0) {
    cloudCompass.push({
      subheader: 'Cloud Altio',
      items: [...cloudCompassItems],
    });
  }

  const data = useMemo(
    () => [
      {
        subheader: t('Overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: 'dashboard',
          },
        ],
      },
      ...management,
      ...cloudCompass,
    ],
    [management, cloudCompass, t]
  );

  return data;
}
