import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from '@/utils/storage-available';

import HttpBackend from 'i18next-http-backend';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';

import moment from 'moment-timezone';
import 'moment/dist/locale/es';
import { defaultLang } from './config-lang';

// import translationEn from './langs/en.json';
// import translationEs from './langs/es.json';

const locizeOptions = {
  // projectId: process.env.REACT_APP_LOCIZE_PROJECTID ?? 'c076f7d5-0c54-4d8b-bfb3-9ba1b878f748',
  // apiKey: process.env.REACT_APP_LOCIZE_APIKEY ?? '11c4b0c2-4eb6-41a7-86ce-172687d10890', // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  // referenceLng: process.env.REACT_APP_LOCIZE_REFLNG ?? 'en',
  // version: process.env.REACT_APP_LOCIZE_VERSION ?? 'latest'
  projectId: 'c076f7d5-0c54-4d8b-bfb3-9ba1b878f748',
  apiKey: '11c4b0c2-4eb6-41a7-86ce-172687d10890', // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: 'en',
  version: 'latest',
  // allowedAddOrUpdateHosts: ['localhost']
};

// locize add --api-key 11c4b0c2-4eb6-41a7-86ce-172687d10890 --project-id c076f7d5-0c54-4d8b-bfb3-9ba1b878f748
// locize sync --api-key 11c4b0c2-4eb6-41a7-86ce-172687d10890 --project-id c076f7d5-0c54-4d8b-bfb3-9ba1b878f748
// locize download --project-id=c076f7d5-0c54-4d8b-bfb3-9ba1b878f748 --ver=latest --clean=true --path=./public/locales
// locize download --project-id c076f7d5-0c54-4d8b-bfb3-9ba1b878f748 --ver latest --path ./public/locales
// import translationFr from './langs/fr.json';
// import translationVi from './langs/vi.json';
// import translationCn from './langs/cn.json';
// import translationAr from './langs/ar.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

const LOCIZE_TRANSLATE = false;
// const isProduction = import.meta.env.PROD;

const localBackendOptions = {
  // ruta a los archivos de traducción
  loadPath: `${!LOCIZE_TRANSLATE ? 'https://cdn.orion.global/hub/statics' : ''}/locales/{{lng}}/translation.json`,
};

if (!LOCIZE_TRANSLATE) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use<any>(LOCIZE_TRANSLATE ? Backend : HttpBackend)
  // .use<any>(LocalBackend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<any>({
    // resources: {
    //   en: { translations: translationEn },
    //   es: { translations: translationEs },
    //   fr: { translations: translationFr },
    //   vi: { translations: translationVi },
    //   cn: { translations: translationCn },
    //   ar: { translations: translationAr },
    // },
    lng,
    fallbackLng: ['en', 'es'],
    saveMissing: LOCIZE_TRANSLATE,
    saveMissingTo: 'all',
    // debug: !isProduction,
    debug: LOCIZE_TRANSLATE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // preload: readdirSync(join(__dirname, '../../public/locales')).filter((fileName) => {
    //   const joinedPath = join(join(__dirname, '../../public/locales'), fileName);
    //   const isDirectory = lstatSync(joinedPath).isDirectory();
    //   return isDirectory;
    // }),
    // backend: locizeOptions,
    backend: LOCIZE_TRANSLATE ? locizeOptions : localBackendOptions,
    locizeLastUsed: locizeOptions,
    // ns: ['translations'],
    // defaultNS: 'translations',
    // interpolation: {
    //   escapeValue: false,
    // },
  });

i18n.on('languageChanged', (newLng) => {
  moment.locale(newLng);
});

export default i18n;
