import { m } from 'framer-motion';
import { Theme, SxProps } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ForbiddenIllustration } from '@/assets/illustrations';
import { varBounce, MotionContainer } from '@/components/animate';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../hooks';

type PermissionGuardProp = {
  hasContent?: boolean;
  module: string;
  submodule: string;
  access: string;
  ownerID?: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function PermissionGuard({
  hasContent,
  children,
  sx,
  module,
  submodule,
  access,
  ownerID,
}: PermissionGuardProp) {
  const { user } = useAuthContext();
  const { t } = useTranslation();

  if (!user?.checkPermission(module, submodule, access, ownerID)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t('Permission Denied')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('You do not have permission to access this page')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
