import Box from '@mui/material/Box';
import Iconify from '../iconify';

type Props = {
  env: string;
};

export function EnvIcon({ env }: Props) {
  if (!env || env === 'production') {
    return null;
  }

  return (
    <Box
      sx={{
        left: 0,
        top: 88,
        zIndex: 999,
        display: 'flex',
        position: 'fixed',
        color: 'text.primary',
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        bgcolor: '#e1234e',
        padding: (theme) => theme.spacing(1, 3, 1, 2),
        boxShadow: (theme) => theme.customShadows.dropdown,
      }}
    >
      <Iconify icon="skill-icons:nestjs-light" sx={{ mr: 1 }} />{' '}
      <strong style={{ color: 'white' }}>{env}</strong>
    </Box>
  );
}
