// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  MANAGEMENT: '/management',
  CLOUD_COMPASS: '/cloud-compass',
  SETTING: '/settings',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    accounts: {
      root: `${ROOTS.MANAGEMENT}/accounts`,

      organizations: {
        root: `${ROOTS.MANAGEMENT}/accounts/organizations`,
        new: `${ROOTS.MANAGEMENT}/accounts/organizations/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/edit`,

        overview: (id: string) => `${ROOTS.MANAGEMENT}/accounts/organizations/${id}`,

        tab: (id: string, tab: string) => `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/${tab}`,
        subTab: (id: string, tab: string, subTab: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/${tab}/${subTab}`,
        tabInformationEdit: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/information/edit`,
        tabInformation: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/information`,

        tabAddDomain: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/digital-assets/domains/new`,
        tabEditDomain: (id: string, domain: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/digital-assets/domains/${domain}/edit`,
        tabCloudAccount: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/digital-assets/accounts`,

        tabAddContact: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/contacts/new`,
        tabEditContact: (id: string, contact: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/contacts/${contact}/edit`,
        tabAddDocument: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/documents/new`,
        tabEditDocument: (id: string, document: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/documents/${document}/edit`,

        tabAddSubscription: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/new`,
        tabEditSubscription: (id: string, subscription: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/edit`,

        tabAddOrder: (id: string) => `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/orders/new`,
        tabEditOrder: (id: string, order: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/orders/${order}/edit`,

        tabAddUser: (id: string) => `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/users/new`,
        tabEditUser: (id: string, user: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/users/${user}/edit`,

        tabInformationSubscription: (id: string, subscription: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/information`,

        tabSubscriptionAddonInformation: (id: string, subscription: string, addonId: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/addons/${addonId}/information`,

        tabViewOrders: (id: string, subscription: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/orders`,

        tabSubscriptionBilling: (id: string, subscription: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/billing`,

        tabSubscriptionAddons: (id: string, subscription: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/subscriptions/${subscription}/addons`,
        tabAddEntity: (id: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/legal-entities/new`,
        tabEditEntity: (id: string, legalEntity: string) =>
          `${ROOTS.MANAGEMENT}/accounts/organizations/${id}/legal-entities/${legalEntity}/edit`,
      },
      contacts: {
        root: `${ROOTS.MANAGEMENT}/accounts/contacts`,
        new: `${ROOTS.MANAGEMENT}/accounts/contacts/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/accounts/contacts/${id}/edit`,
      },
      subscriptions: {
        root: `${ROOTS.MANAGEMENT}/accounts/subscriptions`,
        new: `${ROOTS.MANAGEMENT}/accounts/subscriptions/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/edit`,
        information: (id: string) => `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/information`,
        orders: (id: string) => `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/orders`,
        addons: {
          root: (id: string) => `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/addons`,
          tabAddons: (id: string, addonId: string, tab: string) =>
            `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/addons/${addonId}/${tab}`,

          new: (id: string) => `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/addons/new`,
          information: (id: string, addonId: string) =>
            `${ROOTS.MANAGEMENT}/accounts/subscriptions/${id}/addons/${addonId}/information`,
          edit: (subscriptionId: string, addonId: string) =>
            `${ROOTS.MANAGEMENT}/accounts/subscriptions/${subscriptionId}/addons/${addonId}/edit`,
        },
      },
      orders: {
        root: `${ROOTS.MANAGEMENT}/accounts/orders`,
        new: `${ROOTS.MANAGEMENT}/accounts/orders/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/accounts/orders/${id}/edit`,
        information: (id: string) => `${ROOTS.MANAGEMENT}/accounts/orders/${id}/information`,
      },
      request: {
        root: `${ROOTS.MANAGEMENT}/accounts/requests`,
        users: {
          root: `${ROOTS.MANAGEMENT}/accounts/requests/users`,
          detail: (id: string) => `${ROOTS.MANAGEMENT}/accounts/requests/users/${id}/detail`,
        },
        new: `${ROOTS.MANAGEMENT}/accounts/requests/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/accounts/requests/${id}/edit`,
        information: (id: string) => `${ROOTS.MANAGEMENT}/accounts/requests/${id}/information`,
      },
    },
    portfolio: {
      root: `${ROOTS.MANAGEMENT}/portfolio`,
      products: {
        root: `${ROOTS.MANAGEMENT}/portfolio/products`,
        new: `${ROOTS.MANAGEMENT}/portfolio/products/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/products/${id}/edit`,
        information: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/products/${id}/information`,
        skus: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/products/${id}/skus`,
      },
      partners: {
        root: `${ROOTS.MANAGEMENT}/portfolio/partners`,
        new: `${ROOTS.MANAGEMENT}/portfolio/partners/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/edit`,

        notificationTab: (id: string) =>
          `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/notification`,

        informationTab: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/information`,
        informationTabEdit: (id: string) =>
          `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/information/edit`,
        tab: (id: string, tab: string) => `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/${tab}`,

        tabAddContact: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/contacts/new`,
        tabEditContact: (id: string, contact: string) =>
          `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/contacts/${contact}/edit`,
        tabAddProvider: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/consoles/new`,
        tabEditProvider: (id: string, provider: string) =>
          `${ROOTS.MANAGEMENT}/portfolio/partners/${id}/consoles/${provider}/edit`,
      },
      skus: {
        root: `${ROOTS.MANAGEMENT}/portfolio/skus`,
        new: `${ROOTS.MANAGEMENT}/portfolio/skus/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/skus/${id}/edit`,
      },
      plans: {
        root: `${ROOTS.MANAGEMENT}/portfolio/plans`,
        new: `${ROOTS.MANAGEMENT}/portfolio/plans/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/plans/${id}/edit`,
      },
      pricebook: {
        root: `${ROOTS.MANAGEMENT}/portfolio/pricebook`,
        new: `${ROOTS.MANAGEMENT}/portfolio/pricebook/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/portfolio/pricebook/${id}/edit`,
      },
    },
    directory: {
      root: `${ROOTS.MANAGEMENT}/directory`,
      user: {
        root: `${ROOTS.MANAGEMENT}/directory/users`,
        new: `${ROOTS.MANAGEMENT}/directory/users/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/directory/users/${id}/edit`,
      },
      usermkt: {
        root: `${ROOTS.MANAGEMENT}/directory/us-mkt`,
        new: `${ROOTS.MANAGEMENT}/directory/us-mkt/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/directory/us-mkt/${id}/edit`,
      },
      teams: {
        root: `${ROOTS.MANAGEMENT}/directory/teams`,
        new: `${ROOTS.MANAGEMENT}/directory/teams/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/directory/teams/${id}/edit`,
      },
      roles: {
        root: `${ROOTS.MANAGEMENT}/directory/roles`,
        new: `${ROOTS.MANAGEMENT}/directory/roles/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/directory/roles/${id}/edit`,
      },
      banks: {
        root: `${ROOTS.MANAGEMENT}/directory/bank`,
        new: `${ROOTS.MANAGEMENT}/directory/bank/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/directory/bank/${id}/edit`,
      },
    },
    settings: {
      root: `${ROOTS.MANAGEMENT}/settings`,
      // country: {
      //   root: `${ROOTS.MANAGEMENT}/settings/countries`,
      //   new: `${ROOTS.MANAGEMENT}/settings/countries/new`,
      //   edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/countries/${id}/edit`,
      // },
      // industry: {
      //   root: `${ROOTS.MANAGEMENT}/settings/industries`,
      //   new: `${ROOTS.MANAGEMENT}/settings/industries/new`,
      //   edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/industries/${id}/edit`,
      // },
      // segment: {
      //   root: `${ROOTS.MANAGEMENT}/settings/segments`,
      //   new: `${ROOTS.MANAGEMENT}/settings/segments/new`,
      //   edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/segments/${id}/edit`,
      // },
      // lifeCycle: {
      //   root: `${ROOTS.MANAGEMENT}/settings/life-cycles`,
      //   new: `${ROOTS.MANAGEMENT}/settings/life-cycles/new`,
      //   edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/life-cycles/${id}/edit`,
      // },
      // churnReason: {
      //   root: `${ROOTS.MANAGEMENT}/settings/churn-reasons`,
      //   new: `${ROOTS.MANAGEMENT}/settings/churn-reasons/new`,
      //   edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/churn-reasons/${id}/edit`,
      // },
      accounts: {
        root: `${ROOTS.MANAGEMENT}/settings/accounts/general`,
        security: `${ROOTS.MANAGEMENT}/settings/accounts/security`,
        notifications: `${ROOTS.MANAGEMENT}/settings/accounts/notifications`,
        new: `${ROOTS.MANAGEMENT}/settings/churn-reasons/new`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/settings/churn-reasons/${id}/edit`,
      },
      storefront: {
        root: `${ROOTS.MANAGEMENT}/settings/storefront`,
      },
    },
    integrations: {
      root: `${ROOTS.MANAGEMENT}/integrations`,
      consoles: {
        root: `${ROOTS.MANAGEMENT}/integrations/consoles`,
      },
      apis: {
        root: `${ROOTS.MANAGEMENT}/integrations/apis`,
      },
    },
  },
  cloudCompass: {
    root: `${ROOTS.CLOUD_COMPASS}/cloud`,
    aws: {
      root: `${ROOTS.CLOUD_COMPASS}/cloud/aws`,
      accounts: {
        root: `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts`,
        payerAccounts: {
          root: `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts`,
          new: `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts/new`,
          edit: (id: string, backTo?: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts/${id}/edit${backTo ? `?backTo=${backTo}` : ''}`,
          tab: (id: string, tab: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts/${id}/${tab}`,

          linkedAccounts: {
            edit: (id: string, backTo?: string) =>
              `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts/linked-accounts/${id}/edit${backTo ? `?backTo=${backTo}` : ''}`,
            information: (id: string) =>
              `${ROOTS.CLOUD_COMPASS}/cloud/aws/accounts/payer-accounts/linked-accounts/${id}/information`,
          },
        },
      },
    },
    gcp: {
      root: `${ROOTS.CLOUD_COMPASS}/cloud/gcp`,
      accounts: {
        root: `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts`,
        masterBillingAccounts: {
          root: `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts`,
          new: `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/new`,
          edit: (id: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/${id}/edit`,
          tab: (id: string, tab: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/${id}/${tab}`,
        },
        subAccounts: {
          root: `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/subaccounts`,
          tab: (id: string, tab: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/subaccounts/${id}/${tab}`,
          edit: (id: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/subaccounts/${id}/edit`,
        },
        projects: {
          root: `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/subaccounts/projects`,
          list: (id: string) =>
            `${ROOTS.CLOUD_COMPASS}/cloud/gcp/accounts/billing-accounts/subaccounts/projects/${id}`,
        },
      },
    },
    reports: {
      root: `${ROOTS.CLOUD_COMPASS}/reports`,
      tabs: {
        root: `${ROOTS.CLOUD_COMPASS}/reports/list`,
      },
      consumption: {
        root: `${ROOTS.CLOUD_COMPASS}/reports/consumption`,
      },
      billing: {
        root: `${ROOTS.CLOUD_COMPASS}/reports/billing`,
      },
    },
    resources: {
      root: `${ROOTS.CLOUD_COMPASS}/resources`,
      inventory: {
        root: `${ROOTS.CLOUD_COMPASS}/resources/inventory`,
      },
      tags: {
        root: `${ROOTS.CLOUD_COMPASS}/resources/tags`,
        new: `${ROOTS.CLOUD_COMPASS}/resources/tags/new`,
        edit: (id: string) => `${ROOTS.CLOUD_COMPASS}/resources/tags/${id}/edit`,
      },
    },
  },

  setting: {
    root: ROOTS.SETTING,
    general: {
      root: `${ROOTS.SETTING}/general`,
      countries: {
        root: `${ROOTS.SETTING}/general/countries`,
        new: `${ROOTS.SETTING}/general/countries/new`,
        edit: (id: string) => `${ROOTS.SETTING}/general/countries/${id}/edit`,
      },
      currencies: {
        root: `${ROOTS.SETTING}/general/currencies`,
        new: `${ROOTS.SETTING}/general/currencies/new`,
        edit: (id: string) => `${ROOTS.SETTING}/general/currencies/${id}/edit`,
      },
    },
    accounts: {
      root: `${ROOTS.SETTING}/accounts`,
      industries: {
        root: `${ROOTS.SETTING}/accounts/companies`,
        new: `${ROOTS.SETTING}/accounts/companies/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/${id}/edit`,
      },
      segments: {
        root: `${ROOTS.SETTING}/accounts/companies/segments`,
        new: `${ROOTS.SETTING}/accounts/companies/segments/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/segments/${id}/edit`,
      },
      lifeCycle: {
        root: `${ROOTS.SETTING}/accounts/companies/life-cycles`,
        new: `${ROOTS.SETTING}/accounts/companies/life-cycles/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/life-cycles/${id}/edit`,
      },
      churnReason: {
        root: `${ROOTS.SETTING}/accounts/companies/churn-reasons`,
        new: `${ROOTS.SETTING}/accounts/companies/churn-reasons/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/churn-reasons/${id}/edit`,
      },
      documentType: {
        root: `${ROOTS.SETTING}/accounts/companies/document-types`,
        new: `${ROOTS.SETTING}/accounts/companies/document-types/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/document-types/${id}/edit`,
      },
      contactType: {
        root: `${ROOTS.SETTING}/accounts/companies/contact-types`,
        new: `${ROOTS.SETTING}/accounts/companies/contact-types/new`,
        edit: (id: string) => `${ROOTS.SETTING}/accounts/companies/contact-types/${id}/edit`,
      },
    },

    approvalFlow: {
      root: `${ROOTS.SETTING}/approval-flow`,
      orders: {
        root: `${ROOTS.SETTING}/approval-flow/orders`,
        new: `${ROOTS.SETTING}/approval-flow/orders/new`,
        edit: (id: string) => `${ROOTS.SETTING}/approval-flow/orders/${id}/edit`,
      },
      newuser: {
        root: `${ROOTS.SETTING}/approval-flow/new-user`,
        new: `${ROOTS.SETTING}/approval-flow/new-user/new`,
        edit: (id: string) => `${ROOTS.SETTING}/approval-flow/new-user/${id}/edit`,
      },
    },

    portfolio: {
      root: `${ROOTS.SETTING}/portfolio`,
      productTypes: {
        root: `${ROOTS.SETTING}/portfolio/general`,
        new: `${ROOTS.SETTING}/portfolio/general/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/${id}/edit`,
      },
      businessUnits: {
        root: `${ROOTS.SETTING}/portfolio/general/business-units`,
        new: `${ROOTS.SETTING}/portfolio/general/business-units/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/business-units/${id}/edit`,
      },
      businessLines: {
        root: `${ROOTS.SETTING}/portfolio/general/business-lines`,
        new: `${ROOTS.SETTING}/portfolio/general/business-lines/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/business-lines/${id}/edit`,
      },
      productLines: {
        root: `${ROOTS.SETTING}/portfolio/general/product-lines`,
        new: `${ROOTS.SETTING}/portfolio/general/product-lines/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/product-lines/${id}/edit`,
      },
      studios: {
        root: `${ROOTS.SETTING}/portfolio/general/studios`,
        new: `${ROOTS.SETTING}/portfolio/general/studios/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/studios/${id}/edit`,
      },
      termConditions: {
        root: `${ROOTS.SETTING}/portfolio/general/term-conditions`,
        new: `${ROOTS.SETTING}/portfolio/general/term-conditions/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/term-conditions/${id}/edit`,
      },
      billingFrequency: {
        root: `${ROOTS.SETTING}/portfolio/general/billing-frequency`,
        new: `${ROOTS.SETTING}/portfolio/general/billing-frequency/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/billing-frequency/${id}/edit`,
      },
      externalityReason: {
        root: `${ROOTS.SETTING}/portfolio/general/externality-reasons`,
        new: `${ROOTS.SETTING}/portfolio/general/externality-reasons/new`,
        edit: (id: string) => `${ROOTS.SETTING}/portfolio/general/externality-reasons/${id}/edit`,
      },
    },
    finances: {
      root: `${ROOTS.SETTING}/finances`,
      payments: {
        root: `${ROOTS.SETTING}/finances/payments`,
        new: `${ROOTS.SETTING}/finances/payments/new`,
        edit: (id: string) => `${ROOTS.SETTING}/finances/payments/${id}/edit`,
      },
    },
    access: {
      root: `${ROOTS.SETTING}/access`,
      logs: {
        root: `${ROOTS.SETTING}/access/logs`,
        detail: (id: string) => `${ROOTS.SETTING}/access/logs/${id}`,
      },
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/one`,
  },
};
