import Box, { BoxProps } from '@mui/material/Box';

import Link from '@mui/material/Link';
import { RouterLink } from '@/routes/components';
import { forwardRef } from 'react';
import { ASSETS_API, PATH_AFTER_LOGIN } from '@/config-global';
import { useSettingsContext } from '../settings';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoHorizontal = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          maxWidth: 150,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <img
          src={`${ASSETS_API}/logo/logo-horizontal-${settings.themeMode}.webp`}
          width="100%"
          height="100%"
          alt="logo"
        />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={PATH_AFTER_LOGIN} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default LogoHorizontal;
