import '@/locales/i18n';
import ThemeProvider from '@/theme';
import Router from '@/routes/sections';
import 'simplebar-react/dist/simplebar.min.css';
import { LocalizationProvider } from '@/locales';
import ProgressBar from '@/components/progress-bar';
import { SnackbarProvider } from '@/components/snackbar';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MotionLazy } from '@/components/animate/motion-lazy';
import { AuthConsumer, AuthProvider } from '@/auth/context/jwt';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SettingsDrawer, SettingsProvider } from './components/settings';

const queryClient = new QueryClient();

export default function App() {
  // useScrollToTop();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: true,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    {/* <Provider store={store}> */}
                    <Router />
                    {/* </Provider> */}
                  </AuthConsumer>
                </SnackbarProvider>
              </MotionLazy>
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
