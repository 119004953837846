import Logo from '@/components/logo';
import { useSettingsContext } from '@/components/settings';
import SvgColor from '@/components/svg-color';
import { ASSETS_API } from '@/config-global';
import { useOffSetTop } from '@/hooks/use-off-set-top';
import { useResponsive } from '@/hooks/use-responsive';
import { bgBlur } from '@/theme/css';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import { SearchBar, AccountPopover, LanguagePopover } from '../_common';
import { NAV, HEADER } from '../config-layout';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src={`${ASSETS_API}/assets/icons/navbar/ic_menu_item.svg`} />
        </IconButton>
      )}

      <SearchBar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <Tooltip title={t('Help')}>
          <IconButton
            sx={{ ml: 1 }}
            color="inherit"
            onClick={() => window.open('https://help.marketplace.orion.global/', '_blank')}
          >
            <HelpOutlineIcon sx={{ color: 'text.secondary' }} />
          </IconButton>
        </Tooltip>
        {/* <NotificationsPopover /> */}

        <LanguagePopover />

        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}
        {/* onClick={colorMode.toggleColorMode} */}
        <Tooltip title={t('Theme mode')}>
          <IconButton
            sx={{ ml: 1 }}
            color="inherit"
            onClick={() =>
              settings.onUpdate('themeMode', settings.themeMode === 'dark' ? 'light' : 'dark')
            }
          >
            {settings.themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Tooltip>

        {/* <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            width: 40,
            height: 40,
            ...(popover.open && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
        </IconButton> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        // zIndex: theme.zIndex.appBar + 1,
        zIndex: 900,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,

          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
